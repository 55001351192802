import { NoSsr } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import { m } from 'framer-motion';
import { useCallback } from 'react';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import { allLangs, LanguageValue, useTranslate } from 'src/locales';

export default function LanguagePopover() {
  const { onChangeLang, currentLang } = useTranslate();

  const popover = usePopover();
  const renderFallback = <Box component="span" />;

  const handleChangeLang = useCallback(
    (newLang: LanguageValue) => {
      onChangeLang(newLang);
      popover.onClose();
    },
    [onChangeLang, popover]
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 35,
          height: 35,
          ...(popover.open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Iconify icon={`flag:${currentLang.countryCode.toLowerCase()}-1x1`} />
      </IconButton>
      <NoSsr fallback={renderFallback}>
        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          sx={{ width: 160 }}
          arrow="bottom-right"
        >
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value as LanguageValue)}
            >
              <Iconify icon="" sx={{ borderRadius: 0.65, width: 28 }} />

              {option.label}
            </MenuItem>
          ))}
        </CustomPopover>
      </NoSsr>
    </>
  );
}
